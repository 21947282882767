<template>
  <div class="column">
    <div class="top_back">
      <img
        class="back"
        src="../../assets/img/home/home/PES/icon_pes_back.png"
        @click="back"
      />
    </div>
    <div class="top_l">
      <img
        class="image"
        src="../../assets/img/home/home/PES/Icon_PES_Title.png"
      />
      <div class="title">一份关注孩子们健康成长的测试报告</div>
    </div>
    <div class="row">
      <img
        @mouseover="Mouseover(1)"
        @mouseleave="Mouseleave(1)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_CZFX_d.gif"
        @click="choiceClick(1)"
      />

      <img
        @mouseover="Mouseover(2)"
        @mouseleave="Mouseleave(2)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_QGDB_d.gif"
        @click="choiceClick(2)"
      />

      <img
        @mouseover="Mouseover(3)"
        @mouseleave="Mouseleave(3)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_YDCF_d.gif"
        @click="choiceClick(3)"
      />
    </div>
    <div class="row">
      <img
        @mouseover="Mouseover(4)"
        @mouseleave="Mouseleave(4)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_ZHBG_YS_d.gif"
        @click="choiceClick(4)"
      />

      <img
        @mouseover="Mouseover(5)"
        @mouseleave="Mouseleave(5)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_ZHBG_BJ_d.gif"
        @click="choiceClick(5)"
      />

      <img
        @mouseover="Mouseover(6)"
        @mouseleave="Mouseleave(6)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_ZHBG_YER_d.gif"
        @click="choiceClick(6)"
      />
    </div>
    <div class="row">
      <img
        @mouseover="Mouseover(7)"
        @mouseleave="Mouseleave(7)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_TJKC_d.gif"
        @click="choiceClick(7)"
      />

      <img
        @mouseover="Mouseover(8)"
        @mouseleave="Mouseleave(8)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_SJCX_d.gif"
        @click="choiceClick(8)"
      />

      <img
        @mouseover="Mouseover(9)"
        @mouseleave="Mouseleave(9)"
        class="pointer"
        src="../../assets/img/home/home/PES/Pic_ZZBG_d.gif"
        @click="choiceClick(9)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "PESHome",
  components: {},
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
    };
  },

  methods: {
    choiceClick(index) {
      if (index == 1) {
        this.$router.push({
          path: "/growthAnalyse",
        });
      } else if (index == 2) {
        this.$router.push({
          path: "/allCountrydb",
        });
      } else if (index == 3) {
        this.$router.push({
          path: "/childrenCf",
        });
      } else if (index == 4) {
        this.$router.push({
          path: "/schoolZHFX",
        });
      } else if (index == 5) {
        this.$router.push({
          path: "/gradeZHFX",
        });
      } else if (index == 6) {
        this.$router.push({
          path: "/childrenZHBG",
        });
      } else if (index == 7) {
        this.$hint({ type: "load", message: "敬请期待" }).isShow();
      } else if (index == 8) {
        this.$router.push({
          path: "/childrenCX",
        });
      } else if (index == 9) {
        this.$router.push({
          path: "/doZZBG",
        });
      }
    },
    Mouseover(index) {
      if (index == 1) {
        this.show1 = true;
      } else if (index == 2) {
        this.show2 = true;
      } else if (index == 3) {
        this.show3 = true;
      } else if (index == 4) {
        this.show4 = true;
      } else if (index == 5) {
        this.show5 = true;
      } else if (index == 6) {
        this.show6 = true;
      } else if (index == 7) {
        this.show7 = true;
      } else if (index == 8) {
        this.show8 = true;
      } else if (index == 9) {
        this.show9 = true;
      }
    },
    Mouseleave(index) {
      if (index == 1) {
        this.show1 = false;
      } else if (index == 2) {
        this.show2 = false;
      } else if (index == 3) {
        this.show3 = false;
      } else if (index == 4) {
        this.show4 = false;
      } else if (index == 5) {
        this.show5 = false;
      } else if (index == 6) {
        this.show6 = false;
      } else if (index == 7) {
        this.show7 = false;
      } else if (index == 8) {
        this.show8 = false;
      } else if (index == 9) {
        this.show9 = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
}
.top_back {
  display: flex;
  flex-direction: row;
  height: 42px;
  padding-left: 154px;
  align-items: center;
  background: #333333;
  .back {
    width: 42px;
    height: 15px;
    cursor: pointer;
  }
}
.top_l {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 129px;
  background: #fffefe;
  align-items: center;
  .image {
    width: 233px;
    height: 81px;
    margin-left: 138px;
  }
  .title {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #d40000;
    margin-left: 27px;
  }
}
.row {
  display: flex;
  flex-direction: row;
}
.pointer {
  width: 640px;
  height: 480px;
  cursor: pointer;
}
</style>